/* eslint-disable */
$(document).ready(() => {
  if (!window.NO_JQM) {
    const $display__back = $('.display__back')

    $display__back.css('minHeight', $(window).height() - 75)
    $('.display_height_max').height($display__back.height())
  }

  const link = $('.display__list-link')

  link.click(function () {
    const item = $(this)

    item.addClass('display__click')
    setTimeout(() => {
      item.removeClass('display__click')
    }, 200)
  })
})

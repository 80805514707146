/**
 * @deprecated
 * Полная копия скрипта www/js/mobile.js, за исключением `export` в самом низу,
 * необходим для того, чтобы использовать скрипт в bem-бандлах вместо assets
 */

/* eslint-disable */

$( document ).bind( "pageloadfailed", function( event, data ){
    event.preventDefault();
    location.reload();
});

// Lazy loading

function addMore(page, container) {
    var next_link = $('.scroll_next').last().attr('href');
    if (next_link) {
        $(document).off("scrollstop");
        $('.loading').show();
        $('.scroll_next').remove();
        if ($('select.sort').length > 0) {
            if (next_link.indexOf('?') > -1) {
                next_link += '&sort=' + $('select.sort').last().val();
            } else {
                next_link += '?sort=' + $('select.sort').last().val();
            }
        }

        $.ajax({
            method: "GET",
            url: next_link,
            success: function(data){
                    $('.loading').hide();
                    $(container, page).append(data).listview("refresh");
                    $(document).on("scrollstop", function() {
                        checkScroll(container);
                    });
                },
            statusCode: {
                403: function () {
                    location.reload();
                }
            }
        });
        //$.get(next_link, function(data){
        //    $('.loading').hide();
        //    $(container, page).append(data).listview("refresh");
        //    $(document).on("scrollstop", function() {
        //        checkScroll(container);
        //    });
        //});
    }
}

function checkScroll(container) {
    var activePage = $.mobile.pageContainer.pagecontainer("getActivePage"),
        screenHeight = $.mobile.getScreenHeight(),
        contentHeight = $(".ui-content", activePage).outerHeight(),
        scrolled = $(window).scrollTop(),
        header = $(".ui-header", activePage).outerHeight() - 1,
        footer = $(".ui-footer", activePage).outerHeight() - 1,
        scrollEnd = contentHeight - screenHeight + header + footer;

    if (activePage[0]?.id) {
        if (activePage[0].id == "page" && scrolled >= scrollEnd - 500) {
            addMore(activePage, container);
        }
    }

    var unprocessedImages = $('img[data-original]');
    unprocessedImages.each(function(idx, elem){
        $(elem).attr('src', $(elem).data('original'));
        //$(elem).removeData('original');
    });
}

function initScroll(container) {
    $(document).on("scrollstop", function() {
        checkScroll(container);
        $( document ).on( "pagecontainerbeforehide", function( event, ui ) {
            $(document).off("scrollstop");
        } );
    });
}

//$(document).on('collapsibleexpand', function(e){
//    $('body, html').animate({scrollTop: $(window).scrollTop() + 1});
//});

$(document).on('pagecontainerbeforehide', function (e, ui) {
    $('#yandex_ad_mobile').remove();
    $.mobile.silentScroll(0);

});

$(document).on('click tap', '.rate_reply', function(e) {
    $('.comment_part', this).hide();
    $('.comment_full', this).show();
});

$(document).on('change', '.sms_rate_lpu_filter', function(e){
    var lpu_id = $(this).val();
    var lpu_name = $('.sms_rate_lpu_filter option:selected').data('name');
    $('.sms_lpu_name').text(lpu_name);
    $('.sms_lpu_id').text(lpu_id);
});

export {
    initScroll,
};

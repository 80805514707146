/**
 * @deprecated
 * Скрипт перенесён сюда
 * из шаблона consult/templates/mobile/consult.html
 * в связи с удалением assets.
 */

import { LOGIN_LINKS } from 'constants/links'

/* eslint-disable */

import { initScroll } from 'www/blocks/from_assets.mobile.blocks/common/deprecated_js_mobile/deprecated_js_mobile.js';
import 'imports-loader?additionalCode=>window.jQuery=jQuery;!libs/jquery.ocupload-1.1.2.packed.js';

$('body').on('change', '#spec_groups', function(e){
    var spec_translit = $('#spec_groups').val();
    window.location = window.DATA_FROM_ASSETS.urls.mainPage + spec_translit;
    return false;
});

$("#id_text").focus();
initScroll('.questions');

var interval = -1;


$('.rules_link').off('click tap').on('click tap', function(e) {
    e.stopPropagation();
});

$('.howto_collapse').off('click').on('click', function(e){
    e.preventDefault();
    if ($( ".howto" ).collapsible( "option", "collapsed" )) {
        $( ".howto" ).collapsible( "expand" );
    } else {
        $( ".howto" ).collapsible( "collapse" );
    }
});

if (window.USER.isAnonymous || window.DATA_FROM_ASSETS.isUserPatient) {
    $('.new_question_form').off('submit').on('submit', function(e){
        var cnt=$("#id_text").val().trim().length;

        if ($('#id_gender').val().length == 0) {
            $('#id_gender').focus();
            e.preventDefault();
        }

        if ($('#id_region').val().length == 0) {
            $('#id_region').focus();
            e.preventDefault();
        }

        if ($('#id_age').val().length == 0) {
            $('#id_age').focus();
            e.preventDefault();
        }

        if ($('#id_spec_group').val().length == 0) {
            $('#id_spec_group').focus();
            e.preventDefault();
        }

        if (cnt < 100) {
            $('.chars_left').addClass('errors')
            $("#id_text").focus();
            e.preventDefault();
        }
    });

    function check_length(){
        var cnt=$("#id_text").val().trim().length;
        var min_cnt = 100;
        $(".chars_total").text(cnt);

        if (cnt < 6001) {
            $('.limit_exceeded').hide();
        } else {
            $('.limit_exceeded').show();
        }
        var percent = Math.round(cnt/100*100);
        $(".chars_left").text(percent)
        if (cnt >= min_cnt) {
            $(".chars_left").removeClass("errors");
        }
    };

    $("#id_text").off('keyup').on('keyup', function(event){
        check_length();
    });
}

var scroll;
var $age = $('#id_age')

$age.on('input', function() {
    var $this = $(this);
    var val = $this.val();

    if (val.length > 2) {
        $this.val(val.slice(0, 2));
    }
});

$age.blur(function() {
    var age = $(this).val()
    if(age > 99)
        $(this).val('99')
    if(age < 0)
        $(this).val('0')
    if(age == '')
        $(this).val('')
})

$("#uploaded_images").on('click','.delete_image',function(e){
    e.preventDefault();
    var id = $(this).data('image');
    var key = window.DATA_FROM_ASSETS.formKey;
    $.ajax({
        type: "POST",
        url: window.DATA_FROM_ASSETS.urls.deleteImage,
        data: {
                image: id,
                csrfmiddlewaretoken: window.DATA_FROM_ASSETS.csrfmiddlewaretoken,
                key: key
            },
        success: function(data){
                    if (data == 'OK') {
                        $('#image' + id).remove();
                        $("#images_loaded").text($(".image_block").length);
                        if ($("#images_loaded").text() < 5) {
                            $('#add_image').removeAttr('disabled');
                            $('input[name=ipath]').removeAttr('disabled');
                        }
                    }
               }
    });
});


if ($("#images_loaded").text() >= 5) {
    $('#upload1').attr('disabled', true);
}


var upload = $('#upload1').upload({
     name: 'ipath',
     method: 'POST',
     enctype: 'multipart/form-data',
     action: window.DATA_FROM_ASSETS.urls.newImage,
     params: {
         csrfmiddlewaretoken: window.DATA_FROM_ASSETS.csrfmiddlewaretoken,
         key: window.DATA_FROM_ASSETS.formKey,
     },
     onSubmit: function() {
                $('#add_image').attr('disabled', true);
                $("#uploaded_images").prepend('<div id="image_placeholder" class="image_block"></div>');
                $("#images_loaded").text($(".image_block").length);
     },
     onComplete: function(data) {
        $('#progress1').text('Готово...');
        $('#image_placeholder').remove();
        var dd=eval('('+data+')');
        if (dd.msg == "OK"){
            $("#uploaded_images").prepend('<div id="image' +  dd.id  + '" style="background-image:url(' + dd.preview_url + ');" class="image_block"><img class="delete_image" data-image="' + dd.id + '" src="/static/_v1/pd/icons/red-cross.png"></div>');
            $("#images_loaded").text($(".image_block").length);
        } else {
            alert('Произошла ошибка');
        }
        $('.upload_status').text('');
        if ($("#images_loaded").text() >= 5) {
            $('#add_image').attr('disabled', true);
            $('input[name=ipath]').attr('disabled', true);
        } else {
            $('#add_image').removeAttr('disabled');
            $('input[name=ipath]').removeAttr('disabled');
        }
        $('input[type="file"]').val(null);
     }
});

$("#add_image").click(function(e) {
    $('input[name="ipath"]').click();
    return false;
});

/* eslint-enable */

if (window.location.hash === '#ask' && !window.USER.isAnonymous) {
  $('.new_question_form_wrapper').attr('data-collapsed', 'false')
}

if (window.USER.isAnonymous) {
  $('.new_question_form_title').click(event => {
    event.stopImmediatePropagation()
    window.location.assign(`${LOGIN_LINKS.medtochka}${window.location.hash !== '#ask' ? '%23ask' : ''}`)

    return false
  })
}

/* eslint-disable */
$(document).ready(function () {
    // города из этого списка сокращаются, потому что не влазять в плейсхолдер
    const long_towns = [ 'Санкт-Петербурге', 'Нижнем Новгороде', 'Нижнем Тагиле', 'Нижнем Ломове' ];
    setTimeout(function () {
        $('.twitter-typeahead').css('width', '100%').css('box-shadow', 'none');
    }, 300);
    let end_town = $('.header__search').data('name_pred');
    if (long_towns.indexOf(end_town) !== -1) {
        if (end_town === 'Санкт-Петербурге') {
            end_town = 'СПБ';
        } else {
            end_town = end_town.replace('Нижнем', 'Н.')
        }
    }
    // предположительно из за jquery.mobile происходит небольшое переключение плесхолдера в первые ~0.1/0.3 с.
    // поэтому приходится использовать такой велосипед
    setTimeout(function () {
        $('.header__search').attr("placeholder", "Поиск врача или клиники в " + end_town);
    }, 300);
})
